import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackImage from '../images/svg/back-alliances.svg'
//data
import pageData from "../data/pageData"

const Agradecimiento = ({ location }) => {
  return (
  <Layout location={location}> 
    <img src='https://pixel.loganmedia.mobi/pixel/c/29443' width='1' height='1' alt='' /> 
    <SEO title="Agradecimiento" />
    <Styled>
      <BackImage className='backImage' />
      <div className='process'>
        <h2 className='title'>{pageData.agradecimiento.title}</h2>
        <div className='content' dangerouslySetInnerHTML={{ __html: pageData.agradecimiento.description }}></div>
      </div>
    </Styled>
  </Layout>
  )
}

export default Agradecimiento


const Styled = styled.section `
  position: relative;
  padding: 2rem 0 4rem;
  overflow-x: hidden;
  .backImage{
    position: absolute;
    top: -100px;
    height: auto;
    z-index: -1;
    right: -40px;
    width: 140px;
    transition: all linear .5s;
    #alianzas {
      fill: #09A2AD;
    }
    @media screen and (min-width: 600px){
      width: 200px;
      top: -100px;
      right: -20px;
    }
    @media screen and (min-width: 1100px){
      top: -140px;
      width: 300px;
      right: -50px;
    }
  }
  .process{
    margin: 0 auto 30px;
    max-width:680px;
    padding: 0 2rem 0 1rem;
  }
  .title{
    margin-bottom: 1rem;
  }
  .content{
    color: ${props => props.theme.colors.text.secondary};
    p{
      font-size: 0.875rem;
      line-height: 2em;
      margin-bottom: 1rem;
    }
    p:nth-last-child(1){
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 600px){
    min-height: 400px;
    padding: 3.75rem;
    .title{
      text-align: center;
      margin-bottom: 2rem;
    }
    .content{
      text-align: center;
      p{
        font-size: 1.125rem;
      }
    }
  }
`